import React, { Suspense, useContext, useState, useRef, useEffect } from 'react';
import { Switch, Route, useHistory, Router, useLocation } from "react-router-dom";
import Loader from 'layout/loader/Loader.js';
import { AppConfig, ToDateString } from 'library/Library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faUserCircle, faLock, faInfoCircle, faSignOutAlt, faArrowUpRightFromSquare, faHeadset, faShield, faPhone, faExchange, faShuffle, faCircleInfo, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from 'App';
import useModalStack, { ModalPopUp } from 'hooks/useModalStack';
import ChangePassword from 'layout/topbar/component/ChangePassword';
import { LogoutUser, LogoutUserAlert } from 'modules/login/Login';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import PropertySwitch from './layout/topbar/component/PropertySwitch.js';
import { Menu, MenuItem, MenuDivider, MenuHeader } from '@szhsin/react-menu'


//  SAMPLES
const ContextMenuDemo = React.lazy(() => import('modules/demo/ContextMenuDemo'));
const DummyApiRequest = React.lazy(() => import('modules/demo/DummyApiRequest'));
const HorizontalTabsDemo = React.lazy(() => import('modules/demo/HorizontalTabsDemo'));
const InternalComponentAccessDemo = React.lazy(() => import('modules/demo/InternalComponentAccessDemo/ComponentOne'));
const ModalStackDemo = React.lazy(() => import('modules/demo/ModalStack/ModalStackDemo'));
const Report = React.lazy(() => import('modules/demo/Template/Report/ReportLayout'));
const Template = React.lazy(() => import('modules/demo/Template/Template'));
const VerticalTabsDemo = React.lazy(() => import('modules/demo/VerticalTabsDemo'));


//  APPLICATION SETUP
const AreaTypeSetup = React.lazy(() => import('modules/app/setup/area_type_Setup/AreaTypeSetup.js'));
const CardTypeSetup = React.lazy(() => import('modules/app/setup/card_type_setup/CardTypeSetup.js'));
const CompanyTypeSetup = React.lazy(() => import('modules/app/setup/company_type_setup/CompanyTypeSetup.js'));
const CostCenterSetup = React.lazy(() => import('modules/app/setup/cost_center_setup/CostCenterSetup.js'));
const CurrencySetup = React.lazy(() => import('modules/app/setup/currency_setup/CurrencySetup.js'));
const DepartmentSetup = React.lazy(() => import('modules/app/setup/department_setup/DepartmentSetup.js'));
const DesignationSetup = React.lazy(() => import('modules/app/setup/designation_setup/DesignationSetup.js'));
const EmailTemplateSetup = React.lazy(() => import('modules/app/setup/email_template_setup/Page.js'));
const ExchangeRateSetup = React.lazy(() => import('modules/app/setup/exchange_rate_setup/Page.js'));
const FileTypeSetup = React.lazy(() => import('modules/app/setup/file_type_setup/FileTypeSetup.js'));
const ModuleSettlementSetup = React.lazy(() => import('modules/app/setup/module_settlement_setup/ModuleSettlementSetup.js'));
const OccupationSetup = React.lazy(() => import('modules/app/setup/occupation_setup/OccupationSetup.js'));
const PayableProfileSetup = React.lazy(() => import('modules/app/setup/payable_profile_setup/PayableProfileSetup.js'));
const PaymentModeSetup = React.lazy(() => import('modules/app/setup/payment_mode_setup/PaymentModeSetup.js'));
const PaymentTermSetup = React.lazy(() => import('modules/app/setup/payment_term_setup/PaymentTermSetup.js'));
const PropertySetup = React.lazy(() => import('modules/app/setup/property_setup/PropertySetup.js'));
const ReasonsSetup = React.lazy(() => import('modules/app/setup/reasons_setup/ReasonsSetup.js'));
const ReceivableProfileSetup = React.lazy(() => import('modules/app/setup/receivable_profile_setup/ReceivableProfileSetup.js'));
const ReceivableProfileSubSectionSetup = React.lazy(() => import('modules/app/setup/receivable_profile_sub_section/ReceivableProfileSubSection.js'));
const Roles = React.lazy(() => import('modules/app/setup/roles/Roles.js'));
const SalesExecutiveSetup = React.lazy(() => import('modules/app/setup/sales_executive_setup/SalesExecutiveSetup.js'));
const TaxCodeSetup = React.lazy(() => import('modules/app/setup/tax_code_setup/TaxCodeSetup.js'));
const TaxStructureSetup = React.lazy(() => import('modules/app/setup/tax_structure_setup/TaxStructure.js'));
const User = React.lazy(() => import('modules/app/setup/users/User.js'));
const AutoReportSetup = React.lazy(() => import('modules/app/setup/auto_report_setup/AutoReportSetup.js'));
const APPMemberGroup = React.lazy(() => import('modules/app/setup/membership_group_setup/Page.js'));
const APPMembership = React.lazy(() => import('modules/app/setup/membership_setup/Page.js'));
const APPGuestStatusSetup= React.lazy(() => import('modules/app/setup/guest_status/Page.js'));
const APPVoucherCodeSetup = React.lazy(() => import('modules/app/setup/voucher_code/Page.js'));
const APPGatePassItemSetup = React.lazy(() => import('modules/app/setup/gate_pass_item/GatePassItemSetup.js'));
const AttendantSetup = React.lazy(() => import('modules/app/setup/attendant_setup/Page.js'));
const LoyaltyLink = React.lazy(() => import('modules/app/setup/loyalty_link/Page.js'));
//const IPSecurity = React.lazy(() => import('modules/app/setup/ip_security/Page.js'));

//  APPLICATION TOOLS
const DatabaseBackup = React.lazy(() => import('modules/app/tools/backup/Backup.js'));
const DayEndProcess = React.lazy(() => import('modules/app/tools/day_end_process/DayEndProcess.js'));
//const MembersPointManagement = React.lazy(() => import('modules/app/tools/members_point_management/MembersPointManagement.js'));
const GatePassIssue =React.lazy(() => import('modules/app/tools/gate_pass_issue/GatePassIssue.js'));
const GatePassReturn =React.lazy(() => import('modules/app/tools/gate_pass_return/GatePassReturn.js'));
const OTPManagement = React.lazy(() => import('modules/app/tools/otp_management/OTPManagement.js'));
const Message = React.lazy(() => import('modules/app/tools/message/Page.js'));
const Complaint = React.lazy(() => import('modules/app/tools/complaint/Page.js'));

//  APPLICATION REPORT
const APPActivityLogReport = React.lazy(() => import('modules/app/reports/activity_log/ActivityLog.js'));
const BillPrintReport = React.lazy(() => import('modules/app/reports/bill_print/BillPrintReport.js'));
//const MemberPointsReport = React.lazy(() => import('modules/app/reports/member_points/MemberPointsReport.js'));

const DataDumpReport = React.lazy(() => import('modules/app/reports/data_dump/DataDumpReport.js'));

const MessageReport = React.lazy(() => import('modules/app/reports/message_report/MessageReport.js'));
const ComplaintReport = React.lazy(() => import('modules/app/reports/complaint_report/ComplaintReport.js'));
const ExchangeRateReport = React.lazy(() => import('modules/app/reports/exchange_rate_report/ExchangeRateReport.js'));



//  FRONT OFFICE SETUP
const FODefault = React.lazy(() => import('modules/fo/setup/default_setup/DefaultSetup.js'));
const FOSettings = React.lazy(() => import('modules/fo/setup/fo_settings/FOSettings.js'));
const FOUserRight = React.lazy(() => import('modules/fo/setup/user_right/Page.js'));
const GuestStatusSetup= React.lazy(() => import('modules/fo/setup/guest_status/Page.js'));
const HousekeeperSetup = React.lazy(() => import('modules/fo/setup/housekeeper/Page.js'));
const PackageRate = React.lazy(() => import('modules/fo/setup/package_rate/Page.js'));
const RackRate = React.lazy(() => import('modules/fo/setup/rack_rate/Page.js'));
const ReportTemplate = React.lazy(() => import('modules/fo/setup/report_template/Page.js'));
const ReservationModeSetup = React.lazy(() => import('modules/fo/setup/reservation_mode/Page.js'));
const RevenueCode = React.lazy(() => import('modules/fo/setup/revenue_code/Page.js'));
const RevenueType = React.lazy(() => import('modules/fo/setup/revenue_type/Page.js'));
const RoomCategorySetup = React.lazy(() => import('modules/fo/setup/room_category/Page.js'));
const RoomFeatureSetup = React.lazy(() => import('modules/fo/setup/room_feature/Page.js'));
const RoomSetup = React.lazy(() => import('modules/fo/setup/room_setup/Page.js'));
const ChannelManageRate = React.lazy(() => import('modules/fo/setup/channel_manager_rate/Page.js'));
const AirlineSetup = React.lazy(() => import('modules/fo/setup/airlines_setup/Page.js'));
const FlightSetup = React.lazy(() => import('modules/fo/setup/flight_setup/Page.js'));
const ChannelManager = React.lazy(() => import('modules/fo/setup/channel_manager/ChannelManager.js'));
const GuestProfile = React.lazy(() => import('modules/fo/setup/guest_profile/Page.js'));
const TemplateDesign = React.lazy(() => import('modules/fo/setup/template_design/Page.js'));
// const RoomAmenities = React.lazy(() => import('modules/fo/setup/room_amenities/RoomAmenities.js'));
const ReportKeyword = React.lazy(() => import('modules/fo/setup/report_keyword/Page.js'));
const BlockInventory = React.lazy(() => import('modules/fo/setup/block_inventory/BlockInventory.js'));
const MandatoryField = React.lazy(() => import('modules/fo/setup/mandatory_field/MandatoryField.js'));
// const BookingEngine = React.lazy(() => import('modules/fo/setup/booking_engine/BookingEngine.js'));
// const Amenities = React.lazy(() => import('modules/fo/setup/amenities/Amenities.js'));
const FeerAccountSetup = React.lazy(() => import('modules/fo/setup/feer_account_setup/FeerAccountSetup.js'));
const FeerExchangeRateSetup = React.lazy(() => import('modules/fo/setup/feer_exchange_rate_setup/FeerExchangeRateSetup.js'));
const RevenueBudgetV2 = React.lazy(() => import('modules/fo/setup/report_template_v2/Page.js'));

//  FRONT OFFICE TRANSACTION
const AmendStay = React.lazy(() => import('modules/fo/transaction/amend_stay/Page.js'));
const CancelCheckIn = React.lazy(() => import('modules/fo/transaction/cancel_checkin/Page.js'));
const ChangeRate = React.lazy(() => import('modules/fo/transaction/change_rate/Page.js'));
const Checkout = React.lazy(() => import('modules/fo/transaction/checkout/Checkout.js'));
const Discount = React.lazy(() => import('modules/fo/transaction/discount/Page.js'));
const FOBillRePrint = React.lazy(() => import('modules/fo/transaction/fo_billing/FOBillRePrint.js'));
const FOBillSettlement = React.lazy(() => import('modules/fo/transaction/bill_settlement/FOBillSettlement.js'));
const FOBilling = React.lazy(() => import('modules/fo/transaction/fo_billing/FOBilling.js'));
const FOCheckIn = React.lazy(() => import('modules/fo/transaction/reservation_and_checkin/FOCheckIn.js'));
const FOCreditNote = React.lazy(() => import('modules/fo/transaction/fo_credit_note/Page.js'));
const FOCreditNotePrint = React.lazy(() => import('modules/fo/transaction/fo_credit_note_print/Page.js'));
const FODashboard = React.lazy(() => import('modules/fo/transaction/dashboard/Manage.js'));
const FOReservation = React.lazy(() => import('modules/fo/transaction/reservation_and_checkin/FOReservation.js'));
const FORevenueBudget = React.lazy(() => import('modules/fo/transaction/revenue_budget/FORevenueBudget.js'))
const FORevenueBudgetV2 = React.lazy(() => import('modules/fo/transaction/revenue_budget_v2/FORevenueBudget.js'))
const FoAdditionalRate = React.lazy(() => import('modules/fo/transaction/additional_rate/AdditionalRate.js'));
const FoLinkDeLinkRooms = React.lazy(() => import('modules/fo/transaction/link_delink_rooms/Page.js'));
const FoPayment = React.lazy(() => import('modules/fo/transaction/payment_room/PaymentRoom.js'));
const FoReceipt = React.lazy(() => import('modules/fo/transaction/receipt_room/ReceiptRoom.js'));
const FoRoomStatusUpdate = React.lazy(() => import('modules/fo/transaction/room_status_update/Page.js'));
const GuestUpdate = React.lazy(() => import('modules/fo/transaction/guest_update/Page.js'));
const RoomRatePosting = React.lazy(() => import('modules/fo/transaction/room_rate_posting/RoomRatePosting.js'));
const StopReleasePosting = React.lazy(() => import('modules/fo/transaction/stop_release_posting/StopReleasePosting.js'));
const TransferRooms = React.lazy(() => import('modules/fo/transaction/room_transfer_exchange_room_pax_transfer/Page.js'));
const MessageAndComplain = React.lazy(() => import('modules/fo/transaction/message_and_complaints/MessageAndComplaints.js'));
const FOForeignExchangeEncashment = React.lazy(() => import('modules/fo/transaction/foreign_exchange_encashment/Page.js'));
//const FOBillUpdate = React.lazy(() => import('modules/fo/transaction/bill_update/Page.js'));
const GuestInfoSearch = React.lazy(() => import('modules/fo/transaction/guest_information_search/Page.js'));
const CashBankEntry = React.lazy(() => import('modules/fo/transaction/cash_bank_entry/Page.js'));

//  FRONT OFFICE REPORT
const ArrivalReport = React.lazy(() => import('modules/fo/report/arrival/ArrivalReport.js'));
const ChangeRateReport = React.lazy(() => import('modules/fo/report/change_rate_report/ChangeRateReport.js'));
const CheckinReport = React.lazy(() => import('modules/fo/report/checkin_report/CheckinReport.js'));
const DepartureReport = React.lazy(() => import('modules/fo/report/departure/DepartureReport.js'));
const FOCashierReport = React.lazy(() => import('modules/fo/report/fo_cashier_report/FOCashierReport.js'));
const FODiscountReport = React.lazy(() => import('modules/fo/report/discount_report/DiscountReport.js'));
const FOHighBalanceReport = React.lazy(() => import('modules/fo/report/high_balance_report/HighBalanceReport'));
const FOManagersReport = React.lazy(() => import('modules/fo/report/managers_report/ManagersReport.js'));
const FOMaterializedViewReport = React.lazy(() => import('modules/fo/report/materialized_view/MaterializedView'));
const FOOccupancyReport = React.lazy(() => import('modules/fo/report/occupancy_report/OccupancyReport.js'));
const FORateVarianceReport = React.lazy(() => import('modules/fo/report/rate_variance_report/RateVarianceReport.js'));
const FORevenueBudgetReport = React.lazy(() => import('modules/fo/report/revenue_budget_report/RevenueBudgetReport.js'));
const FORevenueBudgetReportV2 = React.lazy(() => import('modules/fo/report/revenue_budget_report_v2/RevenueBudgetReport.js'));
const FORevenueMateralizedReport = React.lazy(() => import('modules/fo/report/revenue_materalized_report/RevenueMateralizedReport.js'));
const FOSalesTaxRegisterReport = React.lazy(() => import('modules/fo/report/sales_tax_register/SalesTaxRegister'));
const FOVoucherPrint = React.lazy(() => import('modules/fo/report/voucher_print/Page.js'));
const ForecastReport = React.lazy(() => import('modules/fo/report/forecast/ForecastReport.js'));
const GLReport = React.lazy(() => import('modules/fo/report/guest_ledger_report/GuestLedgerReport.js'));
const NightAuditReport = React.lazy(() => import('modules/fo/report/night_audit_report/NightAuditReport.js'));
const RoomFlashReport = React.lazy(() => import('modules/fo/report/room_flash/RoomFlashReport.js'));
const RoomRateOfTheDay = React.lazy(() => import('modules/fo/report/room_rate_of_the_day/RoomRateOfTheDay.js'));
const SalesContributionReport =React.lazy(() => import('modules/fo/report/sales_contribution_report/SalesContributionReport.js'));
const MaterializedAndForecastReport =React.lazy(() => import('modules/fo/report/materialized_and_forecast/MaterializedAndForecastReport.js'));
const TourismReport = React.lazy(()=>import('modules/fo/report/tourism_report/TourismReport.js'));
const GuestMovementReport = React.lazy(()=> import('modules/fo/report/guest_movement_report/GuestMovementReport'));
const ReservationReport =React.lazy(() => import('modules/fo/report/reservation_report/ReservationReport.js'));
const PickUpDropOffReport =React.lazy(() => import('modules/fo/report/pick_up_drop_off_report/PickUpDropOffReport'));
const AmendStayReport = React.lazy(() => import('modules/fo/report/amend_stay/AmendStayReport.js'));
const RoomAllocationReport = React.lazy(() => import('modules/fo/report/room_allocation/RoomAllocationReport.js'));
const RoomStatusLogReport = React.lazy(() => import('modules/fo/report/room_status_log_report/RoomStatusLogReport.js'));
const ComplementaryReport = React.lazy(() => import('modules/fo/report/complementary_report/ComplementaryReport.js'));
const TaxCodeSummaryReport = React.lazy(()=> import('modules/fo/report/tax_code_summary_report/TaxCodeSummaryReport.js'))
const MealPlanSummaryReport = React.lazy(() => import('modules/fo/report/meal_plan_summary_report/MealPlanSummaryReport.js'));
const DailyTransactionEntryReport = React.lazy(() => import('modules/fo/report/daily_transaction_entry_report/DailyTransactionEntryReport.js'));
const ReceiptDetailReport = React.lazy(() => import('modules/fo/report/receipt_detail_report/ReceiptDetailReport.js'));
const ForeignExchangeBalanceReport = React.lazy(() => import('modules/fo/report/foreign_exchange_balance_report/ForeignExchangeBalanceReport.js'));
const RevenueAnalysisReport = React.lazy(() => import('modules/fo/report/revenue_analysis_report/RevenueAnalysisReport.js'));

//  POINT OF SALE SETUP
const KitchenSetup = React.lazy(() => import('modules/pos/setup/kitchen_setup/Page.js'));
const KotGroup = React.lazy(() => import('modules/pos/setup/kot_group_setup/Page.js'));
const KotGroupAndMenuLink = React.lazy(() => import('modules/pos/setup/kot_group_and_menu_link/Page.js'));
const MenuGroup = React.lazy(() => import('modules/pos/setup/menu_group/Page.js'));
const NCAuthorityOutletLink = React.lazy(() => import('modules/pos/setup/outlet_nc_authority_link/Page.js'));
const NCAuthoritySetup = React.lazy(() => import('modules/pos/setup/nc_authority_setup/Page.js'));
const OpenItemSetup = React.lazy(() => import('modules/pos/setup/outlet_open_item/Page.js'));
const OutletMenuGroup = React.lazy(() => import('modules/pos/setup/outlet_menu_group/Page.js'));
const POSContractRateSetup = React.lazy(() => import('modules/pos/setup/contract_rate/Page.js'));
const POSFunctionType = React.lazy(() => import('modules/pos/setup/function_type_setup/Page.js'));
const POSHappyHourSetup = React.lazy(() => import('modules/pos/setup/happy_hour/Page.js'));
const POSMemberGroup = React.lazy(() => import('modules/pos/setup/membership_group_setup/Page.js'));
const POSMembership = React.lazy(() => import('modules/pos/setup/membership_setup/Page.js'));
const POSMenuCagetory = React.lazy(() => import('modules/pos/setup/menu_category/Page.js'));
const POSMenuSetup = React.lazy(() => import('modules/pos/setup/menu_setup/Page.js'));
const POSOrderType = React.lazy(() => import('modules/pos/setup/order_type/Page.js'));
const POSOutletAttendant = React.lazy(() => import('modules/pos/setup/outlet_attendant/Page.js'));
const POSOutletAttendantLink = React.lazy(() => import('modules/pos/setup/outlet_attendant_link/Page.js'));
const POSOutletOrderType = React.lazy(() => import('modules/pos/setup/outlet_order_type/Page.js'));
const POSOutletSession = React.lazy(() => import('modules/pos/setup/outlet_session/Page.js'));
const POSOutletSetup = React.lazy(() => import('modules/pos/setup/outlet_setup/Page.js'));
const POSOutletTable = React.lazy(() => import('modules/pos/setup/outlet_table/Page.js'));
const POSSession = React.lazy(() => import('modules/pos/setup/session/Page.js'));
const POSUserRights = React.lazy(() => import('modules/pos/setup/user_rights/Page.js'));
// const POSVoucherCodeSetup = React.lazy(() => import('modules/pos/setup/voucher_code/Page.js'));
const POSOutletCounterSetup = React.lazy(() => import('modules/pos/setup/outlet_counter_setup/Page.js'));
const CounterGroup = React.lazy(() => import('modules/pos/setup/counter_group/Page.js'));
const CounterKitchenMapping = React.lazy(() => import('modules/pos/setup/counter_kitchen_mapping/Page.js'));
const SeatingArrangement = React.lazy(() => import('modules/pos/setup/seating_arrangement/SeatingArrangement.js'));
const NCUserSetup = React.lazy(() => import('modules/pos/setup/nc_user/Page.js'));

//  POINT OF SALE TRANSACTION
const CoverUpdate = React.lazy(() => import('modules/pos/transaction/cover_update/CoverUpdate.js'));
const POSBillRePrint = React.lazy(() => import('modules/pos/transaction/POSBillRePrint.js'));
const POSBillSettlement = React.lazy(() => import('modules/pos/transaction/POSBillSettlement.js'));
const POSCityLedger = React.lazy(() => import('modules/pos/transaction/city_ledger/CityLedger.js'));
const POSCreditNote = React.lazy(() => import('modules/pos/transaction/POSCreditNote.js'));
const POSCreditNotePrint = React.lazy(() => import('modules/pos/transaction/POSCreditNotePrint.js'));
const POSDashboard = React.lazy(() => import('modules/pos/transaction/POSDashboard.js'));
const POSOrder = React.lazy(() => import('modules/pos/transaction/order/POSOrder.js'));
const POSTouchOrder = React.lazy(() => import('modules/pos/transaction/order/POSOrder.js'));
const PosBilling = React.lazy(() => import('modules/pos/transaction/POSBilling.js'));
const Production = React.lazy(() => import('modules/pos/transaction/production/Production.js'));
const EventBooking = React.lazy(() => import('modules/pos/transaction/event_booking/Page.js'));
const POSTemplateDesign = React.lazy(() => import('modules/pos/setup/template_design/Page.js'));
//const BillUpdate = React.lazy(() => import('modules/pos/transaction/bill_update/Page.js'));

//  POINT OF SALE REPORT
const POSCancellationReport = React.lazy(() => import('modules/pos/report/cancellation_report/CancellationReport'));
const POSCashierReport = React.lazy(() => import('modules/pos/report/cashier_report/CashierReport'));
const POSDiscountReport = React.lazy(() => import('modules/pos/report/discount_report/DiscountReport'));
const POSMaterializedViewReport = React.lazy(() => import('modules/pos/report/materialized_view/MaterializedView'));
const POSNCReport = React.lazy(() => import('modules/pos/report/nc_report/NCReport'));
const POSSalesByItemReport = React.lazy(() => import('modules/pos/report/sales_by_item_report/SalesByItemReport'));
const POSSalesSummaryReport = React.lazy(() => import('modules/pos/report/sales_summary_report/SalesSummaryReport'));
const POSTicketAnalysisReport = React.lazy(() => import('modules/pos/report/ticket_analysis_report/TicketAnalysisReport'));
const POSEventReservationReport = React.lazy(() => import('modules/pos/report/event_reservation_report/EventReservationReport'));
const POSTransactionSummary = React.lazy(() => import('modules/pos/report/transaction_summary_report/TransactionSummaryReport'));
const PosCoverAnalysisReport = React.lazy(() => import('modules/pos/report/cover_analysis/CoverAnalysisReport'));
const POSDeliveryReport = React.lazy(() => import('modules/pos/report/delivery_report/DeliveryReport'));
const ReportPrint = React.lazy(() => import('modules/pos/report/report_print/Page'));

//  INVENTORY SETUP
const INVSettings = React.lazy(() => import('modules/inv/setup/inv_setting/INVSettings.js'));
const InvUserRights = React.lazy(() => import('modules/inv/setup/user_rights/Page.js'));
const OpeningStock = React.lazy(() => import('modules/inv/setup/opening_stock/OpeningStockSetup.js'));
const StoreGroupAccessRight = React.lazy(() => import('modules/inv/setup/store_group_access_right/StoreGroupAccessRight.js'));
const StoreItemGroupSetup = React.lazy(() => import('modules/inv/setup/store_item_group_setup/StoreItemGroupSetup.js'));
const StoreItemSetup = React.lazy(() => import('modules/inv/setup/store_item_setup/StoreItemSetup.js'));
const StoreSetup = React.lazy(() => import('modules/inv/setup/store_setup/StoreSetup.js'));
const StoreStartPeriod = React.lazy(() => import('modules/inv/setup/store_start_date_setup/StoreStartDateSetup.js'));
const StoreLockPeriod = React.lazy(() => import('modules/inv/setup/store_lock_period/StoreLockPeriodSetup'));
const VendorContractSetup = React.lazy(() => import('modules/inv/setup/vendor_contract_setup/VendorContractSetup.js'));
const INVAgeingDefination = React.lazy(() => import('modules/inv/setup/inv_ageing_defination/Page.js'));
const PhysicalStockEntry = React.lazy(() => import('modules/inv/setup/physical_stock_entry/Page.js'));


//  INVENTORY TRANSACTION
const Adjustment = React.lazy(() => import('modules/inv/transaction/adjustment/Adjustment.js'));
const InvDashboard = React.lazy(() => import('modules/inv/transaction/dashboard/Page.js'));
const IssueDirect = React.lazy(() => import('modules/inv/transaction/issue_direct/Page.js'));
const IssueIndent = React.lazy(() => import('modules/inv/transaction/issue_indent/Page.js'));
const IssueReturn = React.lazy(() => import('modules/inv/transaction/issue_return/Page.js'));
const POSecurityCheck = React.lazy(() => import('modules/inv/transaction/po_security_check/Page.js'));
const PurchaseOrder = React.lazy(() => import('modules/inv/transaction/purchase_order/Page.js'));
const PurchaseRequest = React.lazy(() => import('modules/inv/transaction/purchase_request/PurchaseRequest.js'));
const Receipt = React.lazy(() => import('modules/inv/transaction/receipt/Receipt.js'));
const ReceiptReturn = React.lazy(() => import('modules/inv/transaction/receipt_return/ReceiptReturn.js'));
const StoreRequest = React.lazy(() => import('modules/inv/transaction/store_request/Page.js'));
const TransferItem = React.lazy(() => import('modules/inv/transaction/transfer_item/Page.js'));
const TransferCostCenter = React.lazy(() => import('modules/inv/transaction/modify_cost_center/Page.js'));


//  INVENTORY REPORT
const AdjustmentReport = React.lazy(() => import('modules/inv/report/adjustment/AdjustmentReport.js'));
const INVVoucherPrint = React.lazy(() => import('modules/inv/report/voucher_print_v2/Page.js'));
const IssueReport = React.lazy(() => import('modules/inv/report/issue/IssueReport.js'));
const IssueReturnReport = React.lazy(() => import('modules/inv/report/issue_return/IssueReturnReport.js'));
const PurchaseOrderReport = React.lazy(() => import('modules/inv/report/purchase_order/PurchaseOrderReport.js'));
const PurchaseRegister = React.lazy(() => import('modules/inv/report/purchase_register/PurchaseRegisterReport.js'));
const PurchaseRequestReport = React.lazy(() => import('modules/inv/report/purchase_request/PurchaseRequestReport.js'));
const ReceiptReport = React.lazy(() => import('modules/inv/report/receipt/ReceiptReport.js'));
const ReceiptReturnReport = React.lazy(() => import('modules/inv/report/receipt_return/ReceiptReturnReport.js'));
const StockLedgerReport = React.lazy(()=> import('modules/inv/report/stock_ledger/StockLedgerReport.js'));
const StockReport = React.lazy(() => import('modules/inv/report/stock_report/StockReport.js'));
const StoreRequestReport = React.lazy(() => import('modules/inv/report/store_request/StoreRequestReport.js'));
const StoreSummaryReport = React.lazy(() => import('modules/inv/report/store_summary/StoreSummaryReport.js'));
const AgeingDefinationReport = React.lazy(() => import('modules/inv/report/inv_ageing_report/INVAgeingReport.js'));
const ExpiryItemListReport = React.lazy(() => import('modules/inv/report/expiry_item_list/ExpiryItemList.js'));
const PhysicalStockReport = React.lazy(() => import('modules/inv/report/physical_stock_report/PhysicalStockReport.js'));
const TransferItemReport = React.lazy(() => import('modules/inv/report/transfer_item_report/TransferItemReport.js'));
const VendorWiseItemAnalysisReport = React.lazy(() => import('modules/inv/report/vendor_wise_item_analysis_report/VendorWiseItemAnalysisReport.js'));





//  ACCOUNT SETUP
const ACSettings = React.lazy(() => import('modules/ac/setup/ac_settings/ACSettings.js'));
const ACTemplateDesign = React.lazy(() => import('modules/ac/setup/template_design/Page.js'));
const AgeingDefination = React.lazy(() => import('modules/ac/setup/ageing_defination/Page.js'));
const ChartOfAccountSetup = React.lazy(() => import('modules/ac/setup/chart_of_account/ChartOfAccountSetup.js'));
const LinkAR = React.lazy(() => import('modules/ac/setup/link_ar/LinkAR.js'));
const LinkExmptTax = React.lazy(() => import('modules/ac/setup/link_exmpt_tax/LinkExmptTax.js'));
const LinkInventory = React.lazy(() => import('modules/ac/setup/link_inventory/LinkInventory.js'));
const LinkSales = React.lazy(() => import('modules/ac/setup/link_sales/Page.js'));
const MainHeadSetup = React.lazy(() => import('modules/ac/setup/main_head/MainHeadSetup.js'));
const RetainedEarning = React.lazy(() => import('modules/ac/setup/retained_earning/RetainedEarningSetup.js'));
const StartDateSetup = React.lazy(() => import('modules/ac/setup/start_date/StartDateSetup.js'));
const DateLockSetup = React.lazy(() => import('modules/ac/setup/date_lock/DateLockSetup'));
const SubHeadSetup = React.lazy(() => import('modules/ac/setup/sub_head/SubHeadSetup.js'));
const SubLedgerSetup = React.lazy(() => import('modules/ac/setup/sub_ledger/SubLedgerSetup.js'));
const VoucherTypeRight = React.lazy(() => import('modules/ac/setup/voucher_type_right/VoucherTypeRight.js'));
const VoucherTypeSetup = React.lazy(() => import('modules/ac/setup/voucher_type/VoucherTypeSetup.js'));
const openingBalanceEntry = React.lazy(() => import('modules/ac/setup/opening_balance_entry_v2/OpeningBalanceEntry.js'));
const ACReportTemplate = React.lazy(() => import('modules/ac/setup/ac_report_template/ACReportTemplate.js'));
const LinkPayroll = React.lazy(() => import('modules/ac/setup/link_payroll/page.js'));
const PostingLinkSetting = React.lazy(() => import('modules/ac/setup/posting_link_setting/page.js'));
//  ACCOUNT TRANSACTION
//const ACCDashboard = React.lazy(() => import('modules/ac/transaction/dashboard/Page.js'));
const AcBillMatching = React.lazy(() => import('modules/ac/transaction/bill_matching/Page.js'));
const AcCreditNote = React.lazy(() => import('modules/ac/transaction/credit_note/Page.js'));
const AcDebitNote = React.lazy(() => import('modules/ac/transaction/debit_note/Page.js'));
const BankReconciliation = React.lazy(() => import('modules/ac/transaction/bank_reconciliation/Page.js'));
const ConsumptionPosting = React.lazy(() => import('modules/ac/transaction/consumption_posting/Page.js'));
const StatementInvoice = React.lazy(()=> import('modules/ac/transaction/statement_invoice/StatementInvoiceSetup.js'));
const periodicalExpenses = React.lazy(() => import('modules/ac/transaction/periodical_expenses/PeriodicalExpenses.js'));
const purchasePosting = React.lazy(() => import('modules/ac/transaction/purchase_posting/PurchasePosting.js'));
const salesPosting = React.lazy(() => import('modules/ac/transaction/sales_posting/SalesPosting.js'));
const transactionEntry = React.lazy(() => import('modules/ac/transaction/transaction_entry/TransactionEntry.js'));
const BillTransfer = React.lazy(() => import('modules/ac/transaction/bill_transfer/BillTransfer.js'));
const billEntry = React.lazy(() => import('modules/ac/transaction/bill_entry/Page.js'));
const ACPLBudget = React.lazy(() => import('modules/ac/transaction/ac_pl_budget/ACPLBudget.js'));
const ChequePrinting = React.lazy(() => import('modules/ac/transaction/cheque_printing/Page.js'));
const ACPayrollPosting = React.lazy(() => import('modules/ac/transaction/payroll_posting/PayrollPosting.js'));
const InvoiceIntegration = React.lazy(() => import('modules/ac/transaction/invoice_integration/Page.js'));

//  ACCOUNT REPORT
const ACVoucherPrint = React.lazy(() => import('modules/ac/report/voucher_print_v2/Page.js'));
const AccountLinkReport = React.lazy(() => import('modules/ac/report/link_report/LinkReport.js'));
const AgeingReport = React.lazy(() => import('modules/ac/report/ageing_report/AgeingReport.js'));
const BalanceSheetReport = React.lazy(() => import('modules/ac/report/balance_sheet/BalanceSheetReport.js'));
const BankReconciliationReport = React.lazy(() => import('modules/ac/report/bank_reconciliation/BankReconciliationReport.js'));
const CashFlowStatement = React.lazy(() => import('modules/ac/report/bank_and_cash_flow_statement/BankAndCashFlowStatement.js'));
const LedgerReport = React.lazy(() => import('modules/ac/report/ledger_report/LedgerReport.js'));
const ProfitLossReport = React.lazy(() => import('modules/ac/report/pl_report/ProfitLossReport.js'));
const RemainingPostingReport = React.lazy(() => import('modules/ac/report/remaining_posting/RemainingPostingReport.js'));
const TrialBalanceReport = React.lazy(() => import('modules/ac/report/trial_balance_v2/TrialBalanceReport.js'));
const TransactionCheckList = React.lazy(() => import('modules/ac/report/transaction_checklist/TransactionCheckList.js'));
const ACSettlementReport =  React.lazy(() => import('modules/ac/report/settlement_report/SettlementReport'));
const TransactionLogReport = React.lazy(() => import('modules/ac/report/transaction_log_report/TransactionLogReport.js'));
const TaxReport = React.lazy(()=> import('modules/ac/report/tax_report/TaxReport.js'))
const TDSReport = React.lazy(()=> import('modules/ac/report/tds_report/TdsReport.js'))
const StockReconcileReport = React.lazy(() => import('modules/ac/report/stock_reconcile_report/StockReconcileReport.js'));
const CostReconciliationReport = React.lazy(() => import('modules/ac/report/cost_reconciliation_report/CostReconciliationReport.js'));
//const CollectionReport = React.lazy(() => import('modules/ac/report/collection_report/CollectionReport'));
const LedgerReconcileReport = React.lazy(() => import('modules/ac/report/ledger_reconcile_report/LedgerReconcileReport'));
const ReconcileReport = React.lazy(() => import('modules/ac/report/reconcile_report/ReconcileReport.js'));

//  COSTING SETUP
const CostExtractionLinkingPart = React.lazy(() => import('modules/cst/setup/cost_extraction_linking/Page.js'));
const CSTStartDate = React.lazy(()=>import('modules/cst/setup/cst_start_date/CSTStartDateSetup.js'));
const ReceipePart = React.lazy(() => import('modules/cst/setup/receipe/Page.js'));

//  COSTING TRANSACTION
const CostExtraction = React.lazy(() => import('modules/cst/transaction/cost_extraction/Page.js'));
const EventMenuCosting = React.lazy(() => import('modules/cst/transaction/event_menu_costing/EventMenuCosting.js'));

//  CST REPORT
const CostRecipeReport = React.lazy(() => import('modules/cst/report/recipe_report/ReceipeReport.js'));
const CostReport = React.lazy(() => import('modules/cst/report/cost_report/CostReport.js'));
const EventMenuCostingReport = React.lazy(() => import('modules/cst/report/event_menu_costing_report/EventMenuCostingReport.js'));


//  HOUSE KEEPING SETUP
const RoomStatusSetup = React.lazy(() => import('modules/hk/setup/room_status_setup/RoomStatusSetup.js'));
const AmenitiesType = React.lazy(()=>import('modules/hk/setup/amenities_type/AmenitiesType.js') )
const RoomAmenities = React.lazy(() => import('modules/hk/setup/room_amenities/RoomAmenities.js'));

//  HOUSE KEEPING TRANSACTION
const HKDashboard = React.lazy(() => import('modules/hk/transaction/dashboard/Manage.js'));
const HKLostAndFound = React.lazy(() => import('modules/hk/transaction/lost_and_found/LostAndFound.js'));
const HKRoomStatus = React.lazy(() => import('modules/hk/transaction/room_status/RoomStatus.js'));
const LinkAmenities = React.lazy(() => import('modules/hk/transaction/amenities_consumption_v2/Page.js'));



//  HOUSE KEEPING REPORT
const HKDiscrepancyReport = React.lazy(() => import('modules/hk/report/room_status_discrepancy/DiscrepancyReport.js'));

//HR Setup
const StaffProfile = React.lazy(() => import('modules/hr/setup/staff_profile_setup/StaffProfileSetup.js'));
const PaySlip = React.lazy(() => import('modules/hr/setup/pay_slip_setup/PaySlipSetup.js'));
const PayrollHead = React.lazy(() => import('modules/hr/setup/payroll_head/PayrollHeadSetup.js'));
const HRTemplateDesign = React.lazy(() => import('modules/hr/setup/template_design/Page.js'));

//HR Transaction
const PayrollPosting = React.lazy(() => import('modules/hr/transaction/payroll_posting/Page.js'));

//HR Report
const StaffPayrollHistoryReport = React.lazy(() => import('modules/hr/report/staff_payroll_history/StaffPayrollHistory.js'));
const PayslipStructureReport = React.lazy(() => import('modules/hr/report/payslip_structure_report/PayslipStructure.js'));
const BankStatementReport = React.lazy(() => import('modules/hr/report/bank_statement/BankStatement.js'));
const PayrollTransactionReport = React.lazy(() => import('modules/hr/report/earning_deduction_report/EarningDeductionReport.js'));
const HRTaxReport = React.lazy(() => import('modules/hr/report/tax_report/TaxReport.js'));
const StaffProfileDetailReport = React.lazy(() => import('modules/hr/report/staff_profile_detail_report/StaffProfileDetailReport.js'));
const StaffAttendanceReport = React.lazy(() => import('modules/hr/report/staff_attendance_report/StaffAttendanceReport.js'));


// BE Setup
const BEConfiguration = React.lazy(() => import('modules/be/setup/configuration/Page.js'));
const BERoomRateDetails = React.lazy(() => import('modules/be/setup/roomratedetails/Page.js'));
const BEAmenities = React.lazy(() => import('modules/be/setup/amenities/Amenities.js'));
const BERate = React.lazy(() => import('modules/be/setup/rate/Page.js'));



//SNM Transaction   
const Task=React.lazy(() => import('modules/snm/transaction/task/Page.js'));
// SNM Setup
const ContactSetup = React.lazy(() => import('modules/snm/setup/contact/ContactSetup.js'));
const SNMUserRight = React.lazy(() => import('modules/snm/setup/user_rights/Page.js'));
const CallStatusSetup = React.lazy(() => import('modules/snm/setup/call_status/CallStatusSetup.js'));
const PipelineSetup = React.lazy(() => import('modules/snm/setup/pipeline/Pipeline.js'));
const DealForSetup = React.lazy(() => import('modules/snm/setup/deal_for/DealForSetup.js'));

//SNM Transaction
const LeadTransaction = React.lazy(() => import('modules/snm/transaction/lead/LeadTransaction.js'));
const CallLog = React.lazy(() => import('modules/snm/transaction/call_log/CallLog.js'));

//SNM Report
const LeadAndActivityLogReport = React.lazy(() => import('modules/snm/report/lead_and_activity_log_report/LeadAndActivityLogReport.js'));


//  OTHER
const AuthorizationFailureScreen = React.lazy(() => import('./Unauthorized.js'));
const DesignDashboard = React.lazy(() => import('modules/design/Dashboard.js'));
const PageNotFound = React.lazy(() => import('./PageNotFound.js'));





function AppRoute(props) {

    var ac_date = useContext(AppContext).appState.ac_date;
    var property_name = useContext(AppContext).appState.property_name;
    var multi_property_code = useContext(AppContext).appState.multi_property_code;
    var system_files_access_code = useContext(AppContext).appState.system_files_access_code;
    var force_password_change = useContext(AppContext).appState.user.force_password_change;
    var product_name = useContext(AppContext).appState.product;
    var product_version_name = useContext(AppContext).appState.product_version;
    var license = useContext(AppContext).appState.license;
    var isSubWindow = useContext(AppContext).isSubWindow || false;

    if (ac_date == null) ac_date = (<span className='as-text-bold'>Loading...</span>);
    else ac_date = ToDateString(ac_date);


    //  Create Refs
    const ref_change_password = useRef();


    //  Create States
    const [reloadMenu, setReloadMenu] = useState(0);
    const [moduleHasDashboard, setModuleHasDashboard] = useState(false)


    //  Modal Stack Hook
    const [modal, push, pop] = useModalStack();


    //  History Hook
    const {location} = useHistory()
    const currentURLLocation = useLocation()


    //  Action on change of force_password_change and location
    useEffect(() => {
        if(force_password_change) OpenPWChangeModal(force_password_change)
    }, [force_password_change, location])


    //  Open Password Change Modal
    const OpenPWChangeModal = (is_forced) => {
        push(
            <ModalPopUp
                size = "x-small"
                pop = {is_forced ? () => {} : pop}
                key = {1}
                body = {<ChangePassword pop={is_forced ? () => {} : pop} forcepop={() => pop()} ref={ref_change_password} />}
                header = {is_forced ? "Update Your Password Before Proceeding" : "Change Password"}
                footer = {
                    <button
                        onClick={() => ref_change_password.current.UpdatePassword()}
                        type="button"
                        className="as-default-btn-solid"
                    >
                        Update
                    </button>
                }
            />
        )
    };

    const OpenPropertySwitchModal = () => {
        push(
            <ModalPopUp
                size = "x-small"
                pop = {pop}
                key = {1}
                body = {<PropertySwitch pop={pop}/>}
                header = {"Switch Property"}
                footer = {
                    <></>
                }
            />
        )
    };


    //  Check if url has changed
    useEffect(() => {
        if(currentURLLocation) {
            var url = currentURLLocation.pathname
            if(url.includes('/fo/') || url.includes('/pos/')) setModuleHasDashboard(true)
            else setModuleHasDashboard(false)
        }
    }, [currentURLLocation])


    //  Go To Dashboard of module that is active now
    const goToModuleDashboard = () => {
        //  This will match anything after the first '/' until we encounter another '/'
        var current_module = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')
        //  Append dashboard to the active module and redirect to it.
        window.open(`${current_module}/dashboard`, '_self')
    }


    //  MAIN COMPONENT
    return (
        <>

            

            <div className={`as-body-content${isSubWindow ? ' sub-window' : ''}`} id="bodyContent">

                <div className='as-body-content-top-first-row'>
                    <div className='row'>
                        <div className='col-6 col-m-6 col-s-6 col-x-6 as-body-content-top-block as-no-padding-top'>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className='space-left as-text-bold'>{ac_date}</span>
                        </div>
                        {
                            !isSubWindow
                            &&
                        
                            <div className='col-6 col-m-6 col-s-6 col-x-6 as-body-content-top-block as-no-padding-top as-text-right'>
                                {
                                    moduleHasDashboard
                                    &&
                                        <button
                                            className='as-header-redirect-btn'
                                            title='Go To Dashboard'
                                            onClick={() => goToModuleDashboard()}
                                        >
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                            <span className="space-left">Dashboard</span>
                                        </button>
                                }

                                <Menu
                                    menuButton={
                                        <button className='toogle-dropdown as-actions-dropdown-button'>
                                            <FontAwesomeIcon icon={faUserCircle} />
                                            <span className="space-left">{localStorage.full_name}</span>
                                        </button>
                                    }
                                    menuClassName={'as-topbar-menu-list-container'}
                                    align='end'
                                    arrow={true}
                                    transition
                                >
                                    <MenuItem
                                        className={'as-menu-list-item'}
                                        onClick={() => OpenPropertySwitchModal()}
                                    >
                                        <FontAwesomeIcon icon={faShuffle} className='menu-icon as-text-theme' />
                                        Switch Property
                                    </MenuItem>
                                    <MenuItem
                                        className={'as-menu-list-item'}
                                        onClick={() => OpenPWChangeModal()}
                                    >
                                        <FontAwesomeIcon icon={faLock} className='menu-icon as-text-theme' />
                                        Change Password
                                    </MenuItem>
                                    <MenuItem
                                        href="https://knowledgebase.aegis.com.np/shelves/aegis-user-manual"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={'as-menu-list-item'}
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} className='menu-icon as-text-theme' role='presentation' />
                                        Help
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem
                                        className={'as-menu-list-item'}
                                        onClick={() => LogoutUserAlert(false)}
                                    >
                                        <FontAwesomeIcon icon={faRightFromBracket} className='menu-icon as-text-red' />
                                        Logout User
                                    </MenuItem>
                                    <MenuItem
                                        className={'as-menu-list-item'}
                                        onClick={() => LogoutUserAlert(true)}
                                    >
                                        <FontAwesomeIcon icon={faRightFromBracket} className='menu-icon as-text-light' />
                                        Logout & Forget This Device
                                    </MenuItem>
                                    <MenuHeader
                                        className={'as-menu-list-header-info'}
                                    >
                                        <div className='support-info'>
                                            <span className='header'>Support</span>
                                            <div className='support-container'>
                                                <div className='phone-number-container'>
                                                    <span className='phone-number'>980-234-2708</span>
                                                    <span className='phone-number'>980-234-2709</span>
                                                </div>
                                                <div className='icon-container'>
                                                    <FontAwesomeIcon icon={faWhatsapp} />
                                                    <div className='icon-divider' />
                                                    <FontAwesomeIcon icon={faPhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </MenuHeader>
                                </Menu>
                            </div>
                        }
                    </div>
                </div>

                <Suspense fallback={<Loader left="0px" />}>
                    <Switch>
                        {/* APPLICATION SETUP */}
                        <Route path="/app/setup/users" component={User} exact />
                        <Route path="/app/setup/role" component={Roles} exact />
                        <Route path="/app/setup/propertysetup" component={PropertySetup} exact />
                        <Route path="/app/setup/departmentsetup" component={DepartmentSetup} exact />
                        <Route path="/app/setup/costcentersetup" component={CostCenterSetup} exact />
                        <Route path="/app/setup/designationsetup" component={DesignationSetup} exact />
                        <Route path="/app/setup/reasonssetup" component={ReasonsSetup} exact />
                        <Route path="/app/setup/currencysetup" component={CurrencySetup} exact />
                        <Route path="/app/setup/cardtypesetup" component={CardTypeSetup} exact />
                        <Route path="/app/setup/occupationsetup" component={OccupationSetup} exact />
                        <Route path="/app/setup/ReceivableProfileSetup" component={ReceivableProfileSetup} exact />
                        <Route path="/app/setup/ReceivableProfileSubSectionSetup" component={ReceivableProfileSubSectionSetup} exact />
                        <Route path="/app/setup/paymentterm" component={PaymentTermSetup} exact />
                        <Route path="/app/setup/paymentmode" component={PaymentModeSetup} exact />
                        <Route path="/app/setup/exchangerate" component={ExchangeRateSetup} exact />
                        <Route path="/app/setup/PayableProfileSetup" component={PayableProfileSetup} exact />
                        <Route path="/app/setup/modulesettlementsetup" component={ModuleSettlementSetup} exact />
                        <Route path="/app/setup/TaxCodeSetup" component={TaxCodeSetup} exact />
                        <Route path="/app/setup/TaxSetup" component={TaxStructureSetup} exact />
                        <Route path="/app/setup/AreaTypeSetup" component={AreaTypeSetup} exact />
                        <Route path="/app/setup/companytype" component={CompanyTypeSetup} exact />
                        <Route path="/app/setup/FileType" component={FileTypeSetup} exact />
                        <Route path="/app/setup/salesexecutive" component={SalesExecutiveSetup} exact />
                        <Route path="/app/setup/emailtemplate" component={EmailTemplateSetup} exact />
                        <Route path="/app/setup/autoreport" component={AutoReportSetup} exact />
                        <Route path="/app/setup/membershipsetup" component={APPMembership} exact />
                        <Route path="/app/setup/membershipgroupsetup" component={APPMemberGroup} exact />
                        <Route path="/app/setup/GuestStatus" component={APPGuestStatusSetup} exact />
                        <Route path="/app/setup/vouchercode" component={APPVoucherCodeSetup} exact />
                        <Route path="/app/setup/gatepassitemsetup" component={APPGatePassItemSetup} exact /> 
                        <Route path="/app/setup/attendantsetup" component={AttendantSetup} exact /> 
                        <Route path="/app/setup/loyaltylink" component={LoyaltyLink} exact /> 
                        <Route path="/app/tools/message" component={Message} exact /> 
                        <Route path="/app/tools/complaint" component={Complaint} exact /> 
                        {/* <Route path="/app/setup/ipsecurity" component={IPSecurity} exact />  */}
                        {/* APPLICATION TOOLS */}
                        <Route path="/app/tools/dayendprocess" component={DayEndProcess} exact />
                        <Route path="/app/tools/backup" component={DatabaseBackup} exact />
                        {/* <Route path="/app/tools/memberpoint" component={MembersPointManagement} exact /> */}
                        <Route path="/app/tools/gatepassissue" component={GatePassIssue} exact />
                        <Route path="/app/tools/gatepassreturn" component={GatePassReturn} exact />
                        <Route path="/app/tools/otpmanagement" component={OTPManagement} exact />
                        {/* APPLICATION REPORT */}
                        <Route path="/app/report/activitylog" component={APPActivityLogReport} exact />
                        <Route path="/app/report/billprint" component={BillPrintReport} exact />
                        {/* <Route path="/app/report/memberpoint" component={MemberPointsReport} exact /> */}

                        <Route path="/app/report/datadump" component={DataDumpReport} exact />

                        <Route path="/app/report/messagereport" component={MessageReport} exact />
                        <Route path="/app/report/complaintreport" component={ComplaintReport} exact />
                        <Route path="/app/report/exchangeratereport" component={ExchangeRateReport} exact />


                        {/* FRONT OFFICE SETUP */}
                        <Route path="/fo/setup/roomcategorysetup" component={RoomCategorySetup} exact />
                        <Route path="/fo/setup/RoomFeatureSetup" component={RoomFeatureSetup} exact />
                        <Route path="/fo/setup/RoomSetup" component={RoomSetup} exact />
                        <Route path="/fo/setup/revenuetype" component={RevenueType} exact />
                        <Route path="/fo/setup/revenuecode" component={RevenueCode} exact />
                        <Route path="/fo/setup/UserRight" component={FOUserRight} exact />
                        <Route path="/fo/setup/GuestStatus" component={GuestStatusSetup} exact />
                        <Route path="/fo/setup/ReservationMode" component={ReservationModeSetup} exact />
                        <Route path="/fo/setup/HouseKeeper" component={HousekeeperSetup} exact />
                        <Route path="/fo/setup/packagerate" component={PackageRate} exact />
                        <Route path="/fo/setup/rackrate" component={RackRate} exact />
                        <Route path="/fo/setup/FODefaults" component={FODefault} exact />
                        <Route path="/fo/setup/settings" component={FOSettings} exact />
                        <Route path="/fo/setup/reporttemplate" component={ReportTemplate} exact />
                        <Route path="/fo/setup/channelManagerRate" component={ChannelManageRate} exact />
                        <Route path="/fo/setup/airlinesetup" component={AirlineSetup} exact />
                        <Route path="/fo/setup/flightsetup" component={FlightSetup} exact />
                        <Route path="/fo/setup/ChannelManager" component={ChannelManager} exact />
                        <Route path="/fo/setup/GuestProfile" component={GuestProfile} exact />
                        <Route path="/fo/setup/templatedesign" component={TemplateDesign} exact />
                        {/* <Route path="/fo/setup/RoomAmenities" component={RoomAmenities} exact /> */}
                        <Route path="/fo/setup/reportkeywords" component={ReportKeyword} exact />
                        <Route path="/fo/setup/BlockInventory" component={BlockInventory} exact />
                        <Route path="/fo/setup/MandatoryField" component={MandatoryField} exact />
                        {/* <Route path="/fo/setup/bookingenginelink" component={BookingEngine} exact /> */}
                        {/* <Route path="/fo/setup/amenities" component={Amenities} exact /> */}
                        <Route path="/fo/setup/FeerAccountSetup" component={FeerAccountSetup} exact />
                        <Route path="/fo/setup/FeerExchangeRateSetup" component={FeerExchangeRateSetup} exact />
                        <Route path="/fo/setup/ReportTemplateV2" component={RevenueBudgetV2} exact />

                        {/* FRONT OFFICE TRANSACTION */}
                        <Route path="/fo/dashboard" component={FODashboard} exact/>
                        <Route path="/fo/transaction/reservation" component={FOReservation} exact/>
                        <Route path="/fo/transaction/checkin" component={FOCheckIn} exact/>
                        <Route path="/fo/transaction/Receipt" component={FoReceipt} exact />
                        <Route path="/fo/transaction/Payment" component={FoPayment} exact />
                        <Route path="/fo/transaction/additionalrate" component={FoAdditionalRate} exact />
                        <Route path="/fo/transaction/linkdelink" component={FoLinkDeLinkRooms} exact />
                        <Route path="/fo/transaction/changerate" component={ChangeRate} exact />
                        <Route path="/fo/transaction/roomtransferexchange" component={TransferRooms} exact />
                        <Route path="/fo/transaction/stopreleaseposting" component={StopReleasePosting} exact />
                        <Route path="/fo/transaction/roomrateposting" component={RoomRatePosting} exact />
                        <Route path="/fo/transaction/roomstatus"  exact render={props=>(<FoRoomStatusUpdate type="FO" menu_id="20_18" title="Room Status |" />)} />         
                        <Route path="/fo/transaction/billsettlement" component={FOBillSettlement} exact />
                        <Route path="/fo/transaction/amendstay" component={AmendStay} exact />
                        <Route path="/fo/transaction/discount" component={Discount} exact />
                        <Route path="/fo/transaction/guestupdate" component={GuestUpdate} exact />
                        <Route path="/fo/transaction/checkout" component={Checkout} exact />
                        <Route path="/fo/transaction/cancelcheckin" component={CancelCheckIn} exact />
                        <Route path="/fo/transaction/billing" component={FOBilling} exact />
                        <Route path="/fo/transaction/creditnote" component={FOCreditNote} exact />
                        <Route path="/fo/transaction/creditnoteprint" component={FOCreditNotePrint} exact />
                        <Route path="/fo/transaction/billprint" component={FOBillRePrint} exact />
                        <Route path="/fo/transaction/fobudget" component={FORevenueBudget} exact />
                        <Route path="/fo/transaction/messageandcomplaints" component={MessageAndComplain} exact />
                        <Route path="/fo/transaction/foreignexchangeencashment" component={FOForeignExchangeEncashment} exact />
                        <Route path="/fo/transaction/guestinfo" component={GuestInfoSearch} exact />
                        <Route path="/fo/transaction/CashBankEntry" component={CashBankEntry} exact />
                        <Route path="/fo/transaction/FOBudgetV2" component={FORevenueBudgetV2} exact />

                        {/* FRONT OFFICE REPORT */}
                        <Route path="/fo/report/arrival" component={ArrivalReport} exact />
                        <Route path="/fo/report/departure" component={DepartureReport} exact />
                        <Route path="/fo/report/SalesTaxRegister" exact render={props=>(<FOSalesTaxRegisterReport type="fo" menu_id="21_14" title="Sales Tax Register | FO Report | " />)} />
                        <Route path="/fo/report/MaterializedView" component={FOMaterializedViewReport} exact />
                        <Route path="/fo/report/forecast" component={ForecastReport} exact />
                        <Route path="/fo/report/cashier" component={FOCashierReport} exact />
                        <Route path="/fo/report/dailyflash" component={RoomFlashReport} exact />
                        <Route path="/fo/report/guestledger" component={GLReport} exact />
                        <Route path="/fo/report/nightaudit" component={NightAuditReport} exact />
                        <Route path="/fo/report/ChangeRate" component={ChangeRateReport} exact />
                        <Route path="/fo/report/roomrateoftheday" component={RoomRateOfTheDay} exact />
                        <Route path="/fo/report/VoucherPrint" component={FOVoucherPrint} exact />
                        <Route path="/fo/report/managers" component={FOManagersReport} exact />
                        <Route path="/fo/report/occupancy" component={FOOccupancyReport} exact />
                        <Route path="/fo/report/discountreport" component={FODiscountReport} exact />
                        <Route path="/fo/report/highbalance" component={FOHighBalanceReport} exact />
                        <Route path="/fo/report/revenuematerializedreport" component={FORevenueMateralizedReport} exact />
                        <Route path="/fo/report/ratevariance" component={FORateVarianceReport} exact />
                        <Route path="/fo/report/revenuebudget" component={FORevenueBudgetReport} exact />
                        <Route path="/fo/report/RevenueBudgetReportV2" component={FORevenueBudgetReportV2} exact />
                        <Route path="/fo/report/checkinreport" component={CheckinReport} exact />
                        <Route path="/fo/report/salescontributionreport" component={SalesContributionReport} exact />
                        <Route path="/fo/report/MaterializedAndForecastReport" exact render={props=>(<MaterializedAndForecastReport type="fo" menu_id="21_27" title="Materialized and Forecast Report | FO Report | " />)} />
                        <Route path="/fo/report/Tourism" component={TourismReport} exact />
                        <Route path="/fo/report/reservation" component={ReservationReport} exact />
                        <Route path="/fo/report/GuestMovementReport" component={GuestMovementReport} exact />
                        <Route path="/fo/report/PickUpAndDrop" component={PickUpDropOffReport} exact />
                        <Route path="/fo/report/amendstayreport" component={AmendStayReport} exact />
                        <Route path="/fo/report/RoomAllocationReport" component={RoomAllocationReport} exact />
                        <Route path="/fo/report/RoomStatusLogReport" component={RoomStatusLogReport} exact />
                        <Route path="/fo/report/ComplimentaryReport" component={ComplementaryReport} exact />
                        <Route path="/fo/report/taxcodesummaryreport" exact render={props=>(<TaxCodeSummaryReport menu_id="21_33" title="Tax Code Summary Report | FO Report | " type="fo" />)} />  
                        <Route path="/fo/report/ledgerreport" exact render={props=>(<LedgerReport menu_id="20_3" title="Ledger Report | FO Report | " type="fo" />)} /> 

                        <Route path="/fo/report/MealPlanSummaryReport" component={MealPlanSummaryReport} exact />
                        <Route path="/fo/report/DailyTransactionEntryReport" component={DailyTransactionEntryReport} exact />
                        <Route path="/fo/report/ReceiptDetailReport" component={ReceiptDetailReport} exact />
                        <Route path="/fo/report/ForeignExchangeBalanceReport" component={ForeignExchangeBalanceReport} exact />
                        <Route path="/fo/report/revenueanalysis" component={RevenueAnalysisReport} exact />
                        
                        {/* POINT OF SALE SETUP */}
                        <Route path="/pos/setup/outlet" component={POSOutletSetup} exact />
                        <Route path="/pos/setup/outletsession" component={POSOutletSession} exact />
                        <Route path="/pos/setup/session" component={POSSession} exact />
                        <Route path="/pos/setup/outletordertype" component={POSOutletOrderType} exact />
                        <Route path="/pos/setup/ordertype" component={POSOrderType} exact />
                        <Route path="/pos/setup/kitchen" component={KitchenSetup} exact />
                        <Route path="/pos/setup/outlettable" component={POSOutletTable} exact />
                        <Route path="/pos/setup/openitem" component={OpenItemSetup} exact />
                        <Route path="/pos/setup/outletmenugroup" component={OutletMenuGroup} exact />
                        <Route path="/pos/setup/menugroup" component={MenuGroup} exact />
                        <Route path="/pos/setup/menucategory" component={POSMenuCagetory} exact />
                        <Route path="/pos/setup/menu" component={POSMenuSetup} exact />
                        <Route path="/pos/setup/outletattendant" component={POSOutletAttendant} exact />
                        <Route path="/pos/setup/outletattendantlink" component={POSOutletAttendantLink} exact />
                        <Route path="/pos/setup/userright" component={POSUserRights} exact />
                        <Route path="/pos/setup/outletkotgroup" component={KotGroupAndMenuLink} exact />
                        <Route path="/pos/setup/kotgroup" component={KotGroup} exact />
                        <Route path="/pos/setup/happyhour" component={POSHappyHourSetup} exact />
                        <Route path="/pos/setup/outletncauthority" component={NCAuthorityOutletLink} exact />
                        <Route path="/pos/setup/ncauthoritysetup" component={NCAuthoritySetup} exact />
                        <Route path="/pos/setup/contractratesetup" component={POSContractRateSetup} exact />
                        {/* <Route path="/pos/setup/membershipsetup" component={POSMembership} exact />
                        <Route path="/pos/setup/membershipgroupsetup" component={POSMemberGroup} exact /> */}
                        <Route path="/pos/setup/functiontypesetup" component={POSFunctionType} exact />
                        {/* <Route path="/pos/setup/vouchercode" component={POSVoucherCodeSetup} exact /> */}
                        <Route path="/pos/setup/outletcounter" component={POSOutletCounterSetup} exact />
                        <Route path="/pos/setup/countergroup" component={CounterGroup} exact />
                        <Route path="/pos/setup/counterkitchenmapping" component={CounterKitchenMapping} exact />
                        <Route path="/pos/setup/seatingarrangement" component={SeatingArrangement} exact />
                        <Route path="/pos/setup/ncuser" component={NCUserSetup} exact />
                        NCUserSetup
                        {/* POINT OF SALE TRANSACTION */}
                        <Route path="/pos/dashboard" component={POSDashboard} exact />
                        <Route path="/pos/transaction/order" component={POSOrder} exact />
                        <Route path="/pos/transaction/aegistouchorder" component={POSTouchOrder} exact />
                        <Route path="/pos/transaction/bill" component={PosBilling} exact />
                        <Route path="/pos/transaction/settlement" component={POSBillSettlement} exact />
                        <Route path="/pos/transaction/creditnote" component={POSCreditNote} exact />
                        <Route path="/pos/transaction/creditnoteprint" component={POSCreditNotePrint} exact />
                        <Route path="/pos/transaction/billprint" component={POSBillRePrint} exact />
                        <Route path="/pos/transaction/production" component={Production} exact/>
                        <Route path="/pos/transaction/coverupdate" component={CoverUpdate} exact/>
                        <Route path="/pos/transaction/CityLedger" component={POSCityLedger} exact/>
                        <Route path="/pos/transaction/eventbooking" component={EventBooking} exact/>
                        <Route path="/pos/setup/templatedesign" component={POSTemplateDesign} exact />
                        {/* <Route path="/pos/transaction/billupdate" component={BillUpdate} exact /> */}
                        {/* POINT OF SALE REPORT */}
                        <Route path="/pos/report/salestaxregister" exact render={props=>(<FOSalesTaxRegisterReport type="pos" menu_id="31_1" title="Sales Tax Register | POS Report | " />)} />
                        <Route path="/pos/report/materializedreport" component={POSMaterializedViewReport} exact />
                        <Route path="/pos/report/cashierreport" component={POSCashierReport} exact />
                        <Route path="/pos/report/discountreport" component={POSDiscountReport} exact />
                        <Route path="/pos/report/cancellationreport" component={POSCancellationReport} exact />
                        <Route path="/pos/report/ncreport" component={POSNCReport} exact />
                        <Route path="/pos/report/salesbyitem" component={POSSalesByItemReport} exact />
                        <Route path="/pos/report/salessummary" component={POSSalesSummaryReport} exact />
                        <Route path="/pos/report/ticketanalysis" component={POSTicketAnalysisReport} exact />
                        <Route path="/pos/report/eventreservation" component={POSEventReservationReport} exact />
                        <Route path="/pos/report/transactionsummary" component={POSTransactionSummary} exact />
                        <Route path="/pos/report/coveranalysis" component={PosCoverAnalysisReport} exact />
                        <Route path="/pos/report/taxcodesummaryreport" exact render={props=>(<TaxCodeSummaryReport menu_id="31_13" title="Tax Code Summary Report | POS Report | " type="pos" />)} />   
                        <Route path="/pos/report/ledgerreport" exact render={props=>(<LedgerReport menu_id="30_8" title="Ledger Report | POS Report | " type="pos" />)} /> 
                        <Route path="/pos/report/deliveryreport" component={POSDeliveryReport} exact />
                        <Route path="/pos/report/reportprint" component={ReportPrint} exact />
                        
                        {/* INVENTORY SETUP */}
                        <Route path="/inv/setup/StoreSetup" component={StoreSetup} exact />
                        <Route path="/inv/setup/StoreItemGroupSetup" component={StoreItemGroupSetup} exact />
                        <Route path="/inv/setup/StoreItemSetup" component={StoreItemSetup} exact />
                        <Route path="/inv/setup/StoreStartPeriod" component={StoreStartPeriod} exact />
                        <Route path="/inv/setup/DateLock" component={StoreLockPeriod} exact />
                        <Route path="/inv/setup/StoreGroupAccessRightSetup" component={StoreGroupAccessRight} exact />
                        <Route path="/inv/setup/UserRights" component={InvUserRights} exact />
                        <Route path="/inv/setup/openingstock" component={OpeningStock} exact />
                        <Route path="/inv/setup/settings" component={INVSettings} exact />
                        <Route path="/inv/setup/vendorcontract" component={VendorContractSetup} exact />
                        <Route path="/inv/setup/AgeingDefination" component={INVAgeingDefination} exact />
                        <Route path="/inv/setup/physicalstockentry" component={PhysicalStockEntry} exact />



                        {/* INVENTORY TRANSACTION */}
                        <Route path="/inv/dashboard" component={InvDashboard} exact />
                        <Route path="/inv/transaction/StoreRequest" component={StoreRequest} exact />
                        <Route path="/inv/transaction/IssueIndent" component={IssueIndent} exact />
                        <Route path="/inv/transaction/IssueDirect" component={IssueDirect} exact />
                        <Route path="/inv/transaction/IssueReturn" component={IssueReturn} exact />
                        <Route path="/inv/transaction/purchaserequest" component={PurchaseRequest} exact />
                        <Route path="/inv/transaction/PurchaseOrder" component={PurchaseOrder} exact />
                        <Route path="/inv/transaction/receiptreturn" component={ReceiptReturn} exact />
                        <Route path="/inv/transaction/adjustment" component={Adjustment} exact />
                        <Route path="/inv/transaction/Receipt" component={Receipt} exact />
                        <Route path="/inv/transaction/transferitem" component={TransferItem} exact />
                        <Route path="/inv/transaction/posecuritycheck" component={POSecurityCheck} exact />
                        <Route path="/inv/transaction/Receipt" component={Receipt} exact />
                        <Route path="/inv/transaction/transfercostcenter" component={TransferCostCenter} exact />

                        {/* INVENTORY REPORT */}
                        <Route path="/inv/report/issue" component={IssueReport} exact />
                        <Route path="/inv/report/storerequest" component={StoreRequestReport} exact />
                        <Route path="/inv/report/issuereturn" component={IssueReturnReport} exact />
                        <Route path="/inv/report/storesummary" component={StoreSummaryReport} exact />
                        <Route path="/inv/report/receiptreturnreport" component={ReceiptReturnReport} exact />
                        <Route path="/inv/report/adjustmentreport" component={AdjustmentReport} exact />
                        <Route path="/inv/report/receiptreturn" component={ReceiptReturnReport} exact />
                        <Route path="/inv/report/adjustment" component={AdjustmentReport} exact />
                        <Route path="/inv/report/stock" component={StockReport} exact />
                        <Route path="/inv/report/receipt" component={ReceiptReport} exact />
                        <Route path="/inv/report/purchaserequest" component={PurchaseRequestReport} exact />
                        <Route path="/inv/report/purchaseorder" component={PurchaseOrderReport} exact />
                        <Route path="/inv/report/stockledger" component={StockLedgerReport} exact />
                        <Route path="/inv/report/vatpurchaseregister" exact render={props=>(<PurchaseRegister menu_id="41_1" title="Purchase Register Report | INV Report | " type="inv" />)} />   
                        <Route path="/inv/report/voucherprint" component={INVVoucherPrint} exact />
                        <Route path="/inv/report/ageingreport" component={AgeingDefinationReport} exact />
                        <Route path="/inv/report/expiryitemlist" component={ExpiryItemListReport} exact />
                        <Route path="/ac/report/stockreconcilereport" component={StockReconcileReport} exact />                        
                        <Route path="/inv/report/physicalstockreport" component={PhysicalStockReport} exact />
                        <Route path="/inv/report/transferitemreport" component={TransferItemReport} exact />
                        <Route path="/inv/report/vendorwiseitemanalysisreport" component={VendorWiseItemAnalysisReport} exact />
                        

                        {/* ACCOUNT SETUP */}
                        <Route path="/ac/setup/MainHeadSetup" component={MainHeadSetup} exact />
                        <Route path="/ac/setup/SubHeadSetup" component={SubHeadSetup} exact />
                        <Route path="/ac/setup/ChartOfAccountSetup" component={ChartOfAccountSetup} exact />
                        <Route path="/ac/setup/SubLedgerSetup" component={SubLedgerSetup} exact />
                        <Route path="/ac/setup/VoucherTypeSetup" component={VoucherTypeSetup} exact />
                        <Route path="/ac/setup/userright" component={VoucherTypeRight} exact />
                        <Route path="/ac/setup/LinkInventory" component={LinkInventory} exact />
                        <Route path="/ac/setup/StartDate" component={StartDateSetup} exact />
                        <Route path="/ac/setup/DateLock" component={DateLockSetup} exact />
                        <Route path="/ac/setup/LinkAR" component={LinkAR} exact />
                        <Route path="/ac/setup/LinkExmptTax" component={LinkExmptTax} exact />
                        <Route path="/ac/setup/linksales" component={LinkSales} exact />                      
                        <Route path="/ac/setup/openingentry" component={openingBalanceEntry} exact />  
                        <Route path="/ac/setup/settings" component={ACSettings} exact />
                        <Route path="/ac/setup/retainedearning" component={RetainedEarning} exact />
                        <Route path="/ac/setup/AgeingDefination" component={AgeingDefination} exact />
                        <Route path="/ac/setup/templatedesign" component={ACTemplateDesign} exact />
                        <Route path="/ac/setup/reporttemplate" component={ACReportTemplate} exact />
                        <Route path="/ac/setup/linkpayroll" component={LinkPayroll} exact />
                        <Route path="/ac/setup/postinglinksetting" component={PostingLinkSetting} exact />
                        
                        {/* ACCOUNT TRANSACTION */}
                        {/* <Route path="/ac/dashboard" component={ACCDashboard} exact /> */}
                        <Route path="/ac/transaction/transactionentry" component={transactionEntry} exact /> 
                        <Route path="/ac/transaction/periodicalexpenses" component={periodicalExpenses} exact /> 
                        <Route path="/ac/transaction/purchaseposting" component={purchasePosting} exact /> 
                        <Route path="/ac/transaction/salesposting" component={salesPosting} exact /> 
                        <Route path="/ac/transaction/statementinvoice" component={StatementInvoice} exact /> 
                        <Route path="/ac/transaction/consumptionposting" component={ConsumptionPosting} exact />
                        <Route path="/ac/transaction/creditnote" component={AcCreditNote} exact />
                        <Route path="/ac/transaction/debitnote" component={AcDebitNote} exact />
                        <Route path="/ac/transaction/billmatching" component={AcBillMatching} exact />
                        <Route path="/ac/transaction/bankreconciliation" component={BankReconciliation} exact />
                        <Route path="/ac/transaction/billtransfer" component={BillTransfer} exact />
                        <Route path="/ac/transaction/billentry" component={billEntry} exact />                        
                        <Route path="/ac/transaction/PLBudget" component={ACPLBudget} exact />
                        <Route path="/ac/transaction/ChequePrinting" component={ChequePrinting} exact />
                        <Route path="/ac/transaction/PayrollPosting" component={ACPayrollPosting} exact />
                        <Route path="/ac/transaction/InvoiceIntegration" component={InvoiceIntegration} exact />

                        {/* ACCOUNT REPORT */}
                        <Route path="/ac/report/voucherprint" component={ACVoucherPrint} exact />
                        <Route path="/ac/report/TrialBalance" component={TrialBalanceReport} exact />
                        <Route path="/ac/report/PLReport" component={ProfitLossReport} exact />
                        <Route path="/ac/report/ledgerreport" exact render={props=>(<LedgerReport menu_id="51_1" title="Ledger Report | AC Report | " type="ac" />)} />
                        <Route path="/ac/report/AccountLinkModule" component={AccountLinkReport} exact />
                        <Route path="/ac/report/balancesheet" component={BalanceSheetReport} exact />
                        <Route path="/ac/report/remainingposting" component={RemainingPostingReport} exact />
                        <Route path="/ac/report/bankreconciliation" component={BankReconciliationReport} exact />
                        <Route path="/ac/report/ageing" component={AgeingReport} exact />
                        <Route path="/ac/report/cashflowstatement" component={CashFlowStatement} exact />
                        <Route path="/ac/report/transactionchecklist" component={TransactionCheckList} exact />
                        <Route path="/ac/report/settlementreport" component={ACSettlementReport} exact />
                        <Route path="/ac/report/taxreport" component={TaxReport} exact />
                        <Route path="/ac/report/transactionlogreport" component={TransactionLogReport} exact />
                        <Route path="/ac/report/tdsreport" component={TDSReport} exact />                                             
                        <Route path="/ac/report/costreconciliation" component={CostReconciliationReport} exact />
                        <Route path="/ac/report/ledgerreconcilereport" component={LedgerReconcileReport} exact />
                        <Route path="/ac/report/reconcilereport" exact component={ReconcileReport} />
                        
                        
                        {/* <Route path="/ac/report/collectionreport" component={CollectionReport} exact />
                        <Route path="/ac/report/ledgerreconcilereport" component={LedgerReconcileReport} exact />                         */}

                        {/* COSTING SETUP */}
                        <Route path="/cst/setup/recipe" component={ReceipePart} exact />
                        <Route path="/cst/setup/CostExtractionLink" component={CostExtractionLinkingPart} exact />
                        <Route path="/cst/setup/StartDate" component={CSTStartDate} exact />
                        {/* COSTING TRANSACTION */}
                        <Route path="/cst/CostExtraction" component={CostExtraction} exact />
                        <Route path="/cst/EventMenuCosting" component={EventMenuCosting} exact />
                        {/* COSTING REPORT */}
                        <Route path="/cst/report/recipereport" component={CostRecipeReport} exact />
                        <Route path="/cst/report/costreport" component={CostReport} exact />
                        <Route path="/cst/report/eventmenucostingreport" component={EventMenuCostingReport} exact />
                        
                        {/* HOUSE KEEPING SETUP */}
                        <Route path="/hk/setup/roomstatus" component={RoomStatusSetup} exact />
                        <Route path="/hk/setup/amenitiestype" component={AmenitiesType} exact />
                        <Route path="/hk/setup/RoomAmenities" component={RoomAmenities} exact />
                        {/* HOUSE KEEPING TRANSACTION */}
                        <Route path="/hk/dashboard" component={HKDashboard} exact />
                        <Route path="/hk/transaction/roomstatus" component={HKRoomStatus} exact />
                        <Route path="/hk/transaction/lostandfound" component={HKLostAndFound} exact />
                        <Route path="/hk/transaction/foroomStatus" exact render={props=>(<FoRoomStatusUpdate type="HK" menu_id="70_3" title="Room Status Update" />)} />
                        <Route path="/hk/transaction/amenitiesconsumption" component={LinkAmenities} exact />

                        
                        {/* HOUSE KEEPING REPORT */}
                        <Route path="/hk/report/roomstatus" component={HKDiscrepancyReport} exact />
                        {/* HR */}
                        {/* HR Setup*/}
                        
                        <Route path="/hr/setup/staffprofile" component={StaffProfile} exact />
                        <Route path="/hr/setup/PaySlip" component={PaySlip} exact />
                        <Route path="/hr/setup/Payrollhead" component={PayrollHead} exact />
                        <Route path="/hr/setup/templatedesign" component={HRTemplateDesign} exact />

                        {/*HR Transaction*/}
                        <Route path="/hr/transaction/payrollposting" component={PayrollPosting} exact />

                        {/* HR Report */}
                        <Route path="/hr/report/staffpayrollhistory" component={StaffPayrollHistoryReport} exact />
                        <Route path="/hr/report/payslipstructure" component={PayslipStructureReport} exact />
                        <Route path="/hr/report/bankstatement" component={BankStatementReport} exact />
                        <Route path="/hr/report/payrolltransaction" component={PayrollTransactionReport} exact />
                        <Route path="/hr/report/hrtaxreport" component={HRTaxReport} exact />
                        <Route path="/hr/report/staffprofiledetailreport" component={StaffProfileDetailReport} exact />
                        <Route path="/hr/report/staffattendancereport" component={StaffAttendanceReport} exact />



                        {/* Booking Engine */}
                        {/* BE Setup */}
                        <Route path="/be/setup/configuration" component={BEConfiguration} exact />
                        <Route path="/be/setup/roomratedetail" component={BERoomRateDetails} exact />
                        <Route path="/be/setup/amenity" component={BEAmenities} exact />
                        <Route path="/be/setup/rate" component={BERate} exact />
                        
                        {/* BE Transaction */}

                          {/* SNM Transaction*/}
                          <Route path="/snm/transaction/task" component={Task} exact />
                        <Route path="/snm/transaction/calllog" component={CallLog} exact />

                        {/* SNM Setup */}
                        <Route path="/snm/setup/Contact" component={ContactSetup} exact />
                        <Route path="/snm/setup/userrights" component={SNMUserRight} exact />
                        <Route path="/snm/setup/callstatus" component={CallStatusSetup} exact />
                        <Route path="/snm/setup/pipeline" component={PipelineSetup} exact />
                        <Route path="/snm/setup/dealfor" component={DealForSetup} exact />

                        {/* SNM Transaction */}
                        <Route path="/snm/transaction/lead" component={LeadTransaction} exact />

                        {/* SNM Report */}
                        <Route path="/snm/report/forecast" exact render={props=>(<ForecastReport type="snm" menu_id="111_5" title="Forecast Report | SNM Report | " />)} />
                        <Route path="/snm/report/materializedandforecastreport" exact render={props=>(<MaterializedAndForecastReport type="snm" menu_id="111_6" title="Materialized and Forecast Report | SNM Report | " />)} />
                        <Route path="/snm/report/salescontributionreport" exact render={props=>(<SalesContributionReport type="snm" menu_id="111_7" title="Sales Contribution Report | SNM Report | " />)} />
                        <Route path="/snm/report/leadandactivitylogreport" component={LeadAndActivityLogReport} exact />

                        

                        {/* OTHER */}
                        <Route path="/design/dashboard" component={DesignDashboard} />
                        <Route path="/template" component={Template} />
                        <Route path="/dummy-api-request" component={DummyApiRequest} />
                        <Route path="/vertical-tabs" component={VerticalTabsDemo} />
                        <Route path="/horizontal-tabs" component={HorizontalTabsDemo} />
                        <Route path="/modal-stack" component={ModalStackDemo} />
                        <Route path="/context-menu" component={ContextMenuDemo} />
                        <Route path="/internal-component" component={InternalComponentAccessDemo} />
                        <Route path="/report" component={Report} />
                        <Route path="/dashboard" component={DesignDashboard} exact />
                        <Route path="/" component={DesignDashboard} exact />
                        <Route path="/authorizationfailure" component={AuthorizationFailureScreen} exact />
                        <Route path="/" component={PageNotFound} />

                    </Switch>
                </Suspense>
                {modal}
            </div>

            { 
                ((license)=>{
                    if(license==null) return <></>;
                    if(license.days>7) return <></>;

                    return <div className='as-watermark-wrapper'>
                            <div className='as-watermark-container'>
                                {license.days==1?
                                    <span className='as-watermark'>Your license is applicable till today.</span>
                                    :
                                    <span className='as-watermark'>Your license will expire in {(parseInt(license.days))} days.</span>
                                }
                                
                            </div>
                        </div> 
                })(license)
                
            }

            <div className='as-page-footer' id='as-page-footer'>
                <div className='row'>
                    <div className='as-form-wrapper'>
                        <div className='col-6 col-m-6 col-s-12 col-x-12'>
                            <span className='as-highlighted-text'>Aegis{product_name} {product_version_name + " "}</span>
                            Licensed To: <span className='as-highlighted-text'>{property_name} {multi_property_code==''?'':' | '} {multi_property_code}</span>,
                            Valid Till: <span className='as-highlighted-text'>{license!=null?ToDateString(license.validity_period):""}</span>.
                        </div>
                        <div className='col-6 col-m-6 col-s-12 col-x-12 as-text-right'>
                            Copyright &copy; 2022, <span className='as-highlighted-text'> AEGIS SOFTWARE PVT. LTD.</span> All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default AppRoute;