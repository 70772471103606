import React,{useEffect,useState} from 'react';
import {uuidv4} from 'library/Library.js';
// import './Loader.css';
import '../../styles/scss/loader.scss'

/**
 * 
 * @height = 100% or 100px, if you want to force the height it should take 
 * @left = 100% or 100px, if you want to force the left margin it should take 
 */
function Loader(props) {
    const [style, setStyle] = useState({});
    const [uniqueClass,setUniqueClass]=useState(" overlay_"+uuidv4().split('-').join("_"));
    

    useEffect(() => {
        var style=LoaderStyle(uniqueClass);
        if(props.height!=undefined)
            style.height=props.height;
        if(props.left!=undefined)
            style.left=props.left;
        setStyle(style);
    },[setUniqueClass]);

    return (
        <div className={"as-loader-overlay"+uniqueClass} id="overlay-of-refresh" style={style} >
            <div className="as-loader-overlay-inner">
                <div className="as-loader-overlay-content">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                    <div className="dot3"></div>
                </div>
            </div>
        </div>
    );
}

function LoaderStyle(className){
    
    var element=document.getElementsByClassName(className)[0].parentElement;
    
    var positionInfo = element.getBoundingClientRect();
    var height = positionInfo.height;
    var width = positionInfo.width;
    var position = element.getBoundingClientRect();

    var style={
        height:height+"px",
        width:width+"px",
        top:element.offsetTop+"px",
        left:element.offsetLeft+"px"
    };
    return style;
}

export default Loader;